import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { faAddressBook } from '@fortawesome/free-regular-svg-icons';
import { faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';


export default function headerSection() {
    return (
        <header className="header order-last" id="tm-header">
            <nav className="navbar">
                <div className="collapse navbar-collapse single-page-nav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" href="#section-1"><span className="icn"><FontAwesomeIcon icon={faAddressBook} size="2x" /></span> عني</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#section-2"><span className="icn"><FontAwesomeIcon icon={faHandHoldingMedical} size="2x" /></span> خدمات</a>
                        </li>
                      
                        <li className="nav-item">
                            <a className="nav-link" href="#section-3"><span className="icn"><FontAwesomeIcon icon={faComments} size="2x" /></span> تواصل</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    )
}

