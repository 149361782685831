import "bootstrap/dist/css/bootstrap.min.css";

import HeaderSection from "./Sections/headerSection";

import AboutMe from "./Sections/aboutMeSection";

import MyServices from "./Sections/myServices";

import ContactMeSection from "./Sections/contactMeSection";

import './App.css';

function App() {
    return (
        <div className="App" id="outer">


            <main id="content-box" className="order-first">

                <HeaderSection />
                <AboutMe />
                <MyServices />
                <ContactMeSection/>

            </main>
        </div>
    );
}

export default App;
