import React from 'react';
import './aboutMeSection.css';

export default function AboutMe() {
  return (
    <div className="banner-section section parallax-window aboutMe" data-parallax="scroll"  id="section-1">
    <div className="container">
        <div className="item">
            <div className="bg-blue-transparent logo-fa"> مركز الخدمات الطبية المنزلية</div>
            <div className="bg-blue-transparent simple"><p>Home Medical Services Center </p></div>
        </div>
    </div>
</div>
  )
}

