import React from 'react'

export default function myServices() {
    return (
        <section className="work-section section" id="section-2">
            <div className="container">
                <div className="title">
                    <h2>خدمات</h2>
                </div>
                <div className="row ourWork">
                    <div className="item col-md-4">
                        <div className="tm-work-item-inner">
                            <h3>طهور</h3>
                            <p>ختان جراحي كهربائي (طهور)<br/> للصغار و الكبار</p>
                            <p>تصحيح ختان</p>
                        </div>
                    </div>
                    <div className="item col-md-4 one">
                        <div className="tm-work-item-inner">
                            <h3> خدمات تمريضية منزلية</h3>
                            <p>  متابعة مرضى العمليات الجراحية، متابعة مرضى كبار السن في المنزل ، سحب التحاليل المخبرية، إزالة وشم، إزالة وحمة ، إزالة الثآليل والكثير من الخدمات الطبية الأخرى</p>
                        </div>
                    </div>
                    <div className="item col-md-4 two">
                        <div className="tm-work-item-inner">
                            <h3>خدمات متنوعة</h3>
                            <p> تعليق مصل ، غسيل جيوب أنفية، غسيل أذن في المنزل، نزع ظفر ناشب، نزع مسمار لحم، معالجة حروق، معالجة حبة حلب ، فك لجام تحت اللسان، تفجير خراج في الجسم، حجامة إسلامية  </p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
