import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";


export default function contactMeSection() {
    return (
        <section className="contact-section section" id="section-3">
            <div className="container">
                <div className="title">
                    <h3>تواصل معي</h3>
                </div>
                <div className="row">
                    <a href={`https://wa.me/+96176480290`} target="_blank" rel="noopener noreferrer">
                        <button className="whatsapp-button">
                        <FontAwesomeIcon icon={faWhatsapp} /> عبر الواتساب
                        </button>
                    </a>
                </div>
            </div>

        </section>
    )
}
